import Vue from 'vue';
import Filters from '@mixins/Filters';
new Vue({
    el: '#left-sidebar',
    mixins: [Filters],
    data() {
        return {
            currentPath: '/shop',
        };
    },
});
