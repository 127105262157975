import '@global/eventHub';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import ApiRequest from '@services/ApiRequestService';
import SimpleValidation from '@mixins/SimpleValidation';
import LockBody from '@mixins/LockBody';
Vue.use(BootstrapVue);
export default Vue.extend({
    mixins: [SimpleValidation, LockBody],
    data: () => {
        return {
            currentPath: '',
            showFilters: false,
            totalFiltered: 0,
            totalFilteredFormatted: null,
        };
    },
    mounted() {
        setTimeout(() => {
            window.eventHub.$emit('ActiveCategory', this.activeCategory);
        });
    },
    methods: {
        FilterChanged(slug, type, unique = false) {
            // If product is displayed in SWA
            if (window.location !== window.parent.location) {
                window.QsshopSwa.updateFilter(type + '+' + slug, unique);
                return;
            }
            let fullPath = this.currentPath !== ''
                ? this.currentPath
                : location.pathname + location.search;
            if (this.$mediaBreakpointUp('md')) {
                ApiRequest.post('/shop/filter', { slug, type, path: fullPath });
                return;
            }
            let pathArr = fullPath.split('?');
            let path = pathArr[0];
            let search = pathArr[1] ? '?' + pathArr[1] : '';
            ApiRequest.post('/shop/fetch' + search, { slug, type, path }).then((response) => {
                var _a, _b;
                let results = response.data.message;
                this.currentPath = results.url;
                this.totalFiltered = (_a = results.data.total.raw) !== null && _a !== void 0 ? _a : 0;
                this.totalFilteredFormatted = (_b = results.data.total.formatted) !== null && _b !== void 0 ? _b : '';
            });
        },
        ClearFilters() {
            if (window.location !== window.parent.location) {
                window.QsshopSwa.clearFilters();
                return;
            }
        },
        ToggleFilters() {
            this.showFilters = !this.showFilters;
            if (this.showFilters) {
                this.LockBackgroundScroll();
                return;
            }
            this.UnlockBackgroundScroll();
        },
    },
});
